import { useDispatch, useSelector } from 'react-redux';
import { Footer } from '../footer/Footer';
import { CatItem } from './catalogItem/CatItem';
import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import s from './Main.module.scss';
import { get } from '../../lib/api';
import { setCategories } from '../../redux/appState/Dataset';
import Service from '../service/service';
import Rent from '../rent/rent';
import BikeOffers from '../BikeOffers';
import { metrikaEvent } from '../../lib/metrika';

export const Main = () => {
	const language = useSelector((state) => state.app.language);
	const dataset = useSelector((state) => state.dataset);
	const dispatch = useDispatch();
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		get('/bikes/categories', {})
			.then((data) => {
				dispatch(setCategories(data));
			})
			.catch(console.log);
	}, [JSON.stringify(dataset), language]);

	useEffect(() => {
		const scrollToBikes = () => {
			if (searchParams.get('scooter') === 'true') {
				setTimeout(() => {
					scooterRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
				});
			} else {
				window.scrollTo(0, 0);
			}
		};
		window.addEventListener('load', () => {
			scrollToBikes();
		});

		if (document.readyState === 'complete') {
			scrollToBikes();
		}

		return () =>
			window.removeEventListener('load', () => {
				scrollToBikes();
			});
	}, []);

	let title = 'Выбери байк';

	let btnName = 'Найти байк';

	if (language === 'en') {
		title = 'Choose bike';
		btnName = 'Find bike';
	}
	const scooterRef = useRef();
	return (
		<>
			<div className={s.wrapper}>
				<Service />

				<button
					onClick={() => {
						scooterRef.current.scrollIntoView({ behavior: 'smooth' });
						metrikaEvent('найти скутер');
					}}
					className={s.findScBtn}>
					{btnName}
				</button>
				<Rent />

				<div ref={scooterRef} className={s.title}>
					<div className={s.point}>
						<span>1</span>
					</div>
					<span>{title}</span>
				</div>
				<div className={s.items}>
					<CatItem dataset={dataset.mini} type="mini" />
					<CatItem dataset={dataset.highways} type="highways" />
					<CatItem dataset={dataset.luxe} type="luxe" />
				</div>

				<BikeOffers />
			</div>
			<Footer />
		</>
	);
};
