import styles from '../style.module.scss';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { useEffect, useContext, useRef, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Modal from './../../modal/Modal';
import { post } from '../../../lib/api';
import { UserContext } from '../../../context/user';
import { useNavigate } from 'react-router-dom';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { metrikaEvent } from '../../../lib/metrika';

const BikeInfo = ({ displayBikes }) => {
	const swiperRef = useRef();
	const [activeBike, setActiveBike] = useState(displayBikes[0]);
	const [modal, setModal] = useState(false);
	const [sending, setSending] = useState(false);
	const language = useSelector((state) => state.app.language);
	const user = useContext(UserContext);
	const navigate = useNavigate();
	const lastSwipeTime = useRef(0);
	const trasformComponentRef = useRef();

	const imgRef = useRef();

	let kmText = 'км';
	let monthText = 'месяц';
	let bookingText = 'Забронировать';
	let absText = 'ABS';
	let keylessText = 'Беcключевой доступ';
	if (language === 'en') {
		kmText = 'km';
		monthText = 'month';
		bookingText = 'Booking';
		absText = 'ABS';
		keylessText = 'Keyless';
	}

	useEffect(() => {
		const clickWindow = () => {
			setModal(false);
		};

		window.addEventListener('click', clickWindow);

		return () => window.removeEventListener('click', clickWindow);
	}, []);

	return (
		<>
			<div className={styles.bikeCont}>
				<Swiper
					onSwiper={(swiper) => {
						swiperRef.current = swiper;
					}}
					style={{
						'--swiper-pagination-color': '#FFF',
						'--swiper-pagination-bullet-inactive-color': 'rgba(0, 0, 0, 0.40)',
						'--swiper-pagination-bullet-inactive-opacity': '1',
						'--swiper-pagination-bullet-size': '7px',
						'--swiper-pagination-bullet-horizontal-gap': '2px',
					}}
					onSlideChange={(swiper) => {
						setActiveBike(displayBikes[swiper.realIndex]);
						if (
							swiper &&
							swiper.touchEventsData.lastClickTime != 0 &&
							swiper.touchEventsData.lastClickTime !== lastSwipeTime.current
						) {
							if (swiper.swipeDirection === 'next') {
								console.log('swipe next');
								metrikaEvent('Свайп на следующий байк(горячие предложения)');
							} else {
								console.log('swipe prev');
								metrikaEvent('Свайп на предыдущий байк(горячие предложения)');
							}

							lastSwipeTime.current = swiper.touchEventsData.lastClickTime;
						}
					}}
					className={styles.swiperCont}
					spaceBetween={30}
					slidesPerView={1}
					loop
					speed={250}
					effect={'cube'}
					initialSlide={0}
					modules={[Pagination]}
					pagination={{ clickable: true, type: 'bullets' }}>
					{displayBikes.map((bike) => {
						return (
							<SwiperSlide
								className={styles.imgBlock}
								onClick={(e) => {
									e.stopPropagation();
									setModal(true);
								}}>
								<img src={`data:image/jpeg;base64,${bike.image}`} alt={'bike'} />
							</SwiperSlide>
						);
					})}
				</Swiper>

				<div className={styles.bikeInfoCont}>
					<div className={styles.name}>{activeBike.model.name}</div>
					<div className={styles.price}>
						${activeBike.usd_monthly_price} <span>{monthText}</span>
					</div>

					<div className={styles.info}>
						<span>{activeBike.year}</span>
						{activeBike.abs && <span>{absText}</span>}
						{activeBike.keyless && <span>{keylessText}</span>}
						<span>
							{activeBike.mileage} {kmText}
						</span>
					</div>
					<button
						onClick={() => {
							if (!sending) {
								metrikaEvent('нажатие кнопки букинг');
								setSending(true);
								post('/requests/bookingBike', { token: user.token }, { bike_id: activeBike.id })
									.then((res) => {
										if (res.status === 'ok') {
											navigate('/wait');
										}
										setSending(false);
									})
									.catch((err) => {
										console.log(err);
										setSending(false);
									});
							}
						}}>
						{sending ? (
							<svg
								className={`${styles.svgLoader}`}
								viewBox="0 0 100 100"
								xmlns="http://www.w3.org/2000/svg">
								<circle cx="50" cy="50" r="45" />
							</svg>
						) : (
							bookingText
						)}
					</button>
				</div>
			</div>

			<div className={styles.infoBtns}>
				<button
					onClick={() => {
						swiperRef.current.slidePrev();
						metrikaEvent('клик на предыдущий байк(горячие предложения)');
					}}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none">
						<path
							d="M12.5 15L7.5 10L12.5 5"
							stroke="#202020"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</button>

				<button
					onClick={() => {
						swiperRef.current.slideNext();
						metrikaEvent('клик на следующий байк(горячие предложения)');
					}}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none">
						<path
							d="M7.5 15L12.5 10L7.5 5"
							stroke="#202020"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</button>
			</div>

			<Modal className={styles.modal} active={modal}>
				{modal && (
					<>
						<button
							onClick={(e) => {
								setModal(false);
								e.stopPropagation();
							}}
							className={styles.closeBtn}>
							<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z" />
							</svg>
						</button>
						<div className={styles.imgContZoom} onClick={(e) => e.stopPropagation()}>
							<TransformWrapper maxScale={3} initialScale={1} ref={trasformComponentRef}>
								<TransformComponent>
									<img
										ref={imgRef}
										src={`data:image/jpeg;base64,${activeBike.image}`}
										alt={'Bike'}
									/>
								</TransformComponent>
							</TransformWrapper>
						</div>
					</>
				)}
			</Modal>
		</>
	);
};

export default BikeInfo;
