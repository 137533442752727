import { useSelector } from 'react-redux';
import s from './rent.module.scss';
const Rent = () => {
	const language = useSelector((state) => state.app.language);

	const texts = [
		{ ru: 'Как арендовать байк', en: 'How to rent a bike' },
		{ ru: 'Выбери байк', en: 'Choose bike' },
		{ ru: 'Укажи срок аренды', en: 'Specify the rental period' },
		{ ru: 'Нажми “Найти байк”', en: 'Click "Find a bike"' },
		{
			ru: 'Оставь запрос и получи список предложений с реальной фотографияей, итоговой ценой, комплектацией и пробегом (км)',
			en: 'Leave a request and receive a list of offers with real photos, final price, equipment, and mileage (km)',
		},
	];
	return (
		<>
			<div className={s.header}>{language === 'ru' ? texts[0].ru : texts[0].en}</div>
			<div className={s.block}>
				<ul>
					<li>
						<svg
							width="21"
							height="21"
							viewBox="0 0 21 21"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path d="M2 12.5L7.25 17L17 5" stroke="#3F77B5" stroke-width="2" />
						</svg>
						<span>{language === 'ru' ? texts[1].ru : texts[1].en}</span>
					</li>

					<li>
						<svg
							width="21"
							height="21"
							viewBox="0 0 21 21"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path d="M2 12.5L7.25 17L17 5" stroke="#3F77B5" stroke-width="2" />
						</svg>
						<span>{language === 'ru' ? texts[2].ru : texts[2].en}</span>
					</li>

					<li>
						<svg
							width="21"
							height="21"
							viewBox="0 0 21 21"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path d="M2 12.5L7.25 17L17 5" stroke="#3F77B5" stroke-width="2" />
						</svg>
						<span>{language === 'ru' ? texts[3].ru : texts[3].en}</span>
					</li>
				</ul>

				<div>{language === 'ru' ? texts[4].ru : texts[4].en}</div>
			</div>
		</>
	);
};

export default Rent;
