import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSelectedBike, setSlideIndex } from '../../../redux/appState/Dataset';
import SwiperBlock from '../../swiperBlock/SwiperBlock';

import s from './CatItem.module.scss';
import { useContext, useState } from 'react';
import { OptionsContext } from '../../../context/misc';
import { metrikaEvent } from '../../../lib/metrika';

const TRANSLATIONS = {
	ru: {
		mini: {
			title: 'Мини байки',
			subtitle: 'Для девушек / одиночных поездок',
		},
		highways: {
			title: 'Шоссейные',
			subtitle: 'Для путешествий вдвоем',
		},
		luxe: {
			title: 'Премиум',
			subtitle: 'Для поклонников ретро / семьи',
		},
		price_from: 'от',
	},
	en: {
		mini: {
			title: 'Mini bikes',
			subtitle: 'For girls / single trips',
		},
		highways: {
			title: 'Highways',
			subtitle: 'For traveling together',
		},
		luxe: {
			title: 'Premium',
			subtitle: 'For retro / family fans',
		},
		price_from: 'from',
	},
};

const infoItem = {
	freeDel: {
		ru: 'Бесплатная доставка',
		en: 'Free delivery',
	},
	pay: {
		ru: 'Оплата при получении',
		en: 'Payment upon receipt',
	},

};

export const CatItem = ({ dataset, type, activeIndex }) => {
	const language = useSelector((state) => state.app.language);
	const options = useContext(OptionsContext);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const translations = TRANSLATIONS[language] || TRANSLATIONS['ru'];
	const text = translations[type];

	function setState() {
		dispatch(
			setSelectedBike({
				type: type,
				model_id: dataset.items[dataset.activeIndex].id,
				price: dataset.items[dataset.activeIndex].price,
				name: '',
				desc: dataset.items[dataset.activeIndex].desc,
				date_at: '',
				date_to: '',
				color: '',
				helmet_count: '',
				options: {
					abs: false,
					keyless_access: false,
				},
			}),
		);
	}

	return (
		<div
			className={s.wrapper}
			onClick={() => {
				setState();
				navigate('/catalog');
				metrikaEvent('выбор типа скутера');
			}}>
			<div style={{ width: '100%' }}>
				<SwiperBlock
					swiperName={type}
					type={type}
					autoplay={true}
					bikes={dataset.items}
					activeIndex={dataset.activeIndex}
				/>
			</div>

			<div className={s.info}>
				<div className={s.title_price}>
					<span>{text.title}</span>
					{dataset.items[dataset.activeIndex] ? (
						<span>
							{language === 'ru' ? TRANSLATIONS.ru.price_from : TRANSLATIONS.en.price_from}{' '}
							{dataset.items[dataset.activeIndex]?.price.toFixed(2)}$
						</span>
					) : (
						''
					)}
				</div>
				<div className={s.subtitle}>
					<span>{text.subtitle}</span>
				</div>

				<div className={s.paymentMethods}>
					<span>{language === 'ru' ? infoItem.freeDel.ru : infoItem.freeDel.en}</span>
					<span>{language === 'ru' ? infoItem.pay.ru : infoItem.pay.en}</span>
				</div>
			</div>
		</div>
	);
};
