import { useSelector } from 'react-redux';
import { Footer } from '../footer/Footer';
import s from './About.module.scss';

import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export const About = () => {
	const language = useSelector((state) => state.app.language);
	const navigate = useNavigate();

	function setLocale() {
		const loctext = {};
		if (language == 'ru') {
			loctext.title = 'Как работает наш сервис';
			loctext.h1 = 'Каталог предложений';
			loctext.t1 =
				'Владельцы байков размещают свои предложения. Они указывают модель, год выпуска, пробег, комплектацию и цену. Загружают фотографию байка';

			loctext.h2 = 'Заявка на аренду байка';
			loctext.t2 =
				'При помощи удобного интерфейса клиент оставляет заявку на поиск. Для этого достаточно указать тип байка, модель и срок аренды. Далее умный поиск сделает все автоматически';

			loctext.h3 = 'Умный поиск';
			loctext.t3 =
				'Система сравнивает предложения от владельцев, учитывая предпочтения клиента и отбирает самые лучшие байки. Поиск учитывает год выпуска, пробег, цену и комплектацию';

			loctext.h4 = 'Предоставление результатов';
			loctext.t4 =
				'В автоматическом режиме система отправляет запрос на бронирование. Байки, которые получили подтверждение от владельцев показываются клиенту в личных сообщениях мессенджера Telegram. ';

			loctext.h5 = 'Выбор для клиента';
			loctext.t5 =
				'Клиент получает несколько самых лучших предложений. Ему остается только нажать кнопку “Хочу этот байк” и с ним свяжется менеджер, чтобы организовать доставку. ';
		} else if (language == 'en') {
			loctext.title = 'How our service works';
			loctext.h1 = 'Offers Catalog';
			loctext.t1 =
				'Bike owners post their offers. They provide the model, year of manufacture, mileage, equipment, and price. They upload a photo of the bike';

			loctext.h2 = 'Bike Rental Request';
			loctext.t2 =
				'Using a convenient interface, the client submits a request for a search. It is enough to specify the type of bike, model, and rental period. Then the smart search will do everything automatically';

			loctext.h3 = 'Smart Search';
			loctext.t3 =
				"The system compares offers from owners, taking into account the client's preferences, and selects the best bikes. The search takes into account the year of manufacture, mileage, price, and equipment";

			loctext.h4 = 'Providing Results';
			loctext.t4 =
				'In automatic mode, the system sends a booking request. Bikes that have received confirmation from the owners are shown to the client in private messages of the Telegram messenger. ';

			loctext.h5 = 'Selection for the Client';
			loctext.t5 =
				'The client receives several of the best offers. All he has to do is click the "I want this bike" button and a manager will contact him to arrange delivery. ';
		}
		return loctext;
	}

	const text = setLocale();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<div className={s.wrapper} onClick={() => navigate('/support')}>
				<div className={s.title}>{text.title}</div>
				<div className={s.item}>
					<div className={s.wrapperNum}>
						<div className={s.number}>1</div>
						<div className={s.border}></div>
					</div>
					<div className={s.text}>
						<div>{text.h1}</div>
						<div>{text.t1}</div>
					</div>
				</div>

				<div className={s.item}>
					<div className={s.wrapperNum}>
						<div className={s.number}>2</div>
						<div className={s.border}></div>
					</div>
					<div className={s.text}>
						<div>{text.h2}</div>
						<div>{text.t2}</div>
					</div>
				</div>

				<div className={s.item}>
					<div className={s.wrapperNum}>
						<div className={s.number}>3</div>
						<div className={s.border}></div>
					</div>

					<div className={s.text}>
						<div>{text.h3}</div>
						<div>{text.t3}</div>
					</div>
				</div>
				<div className={s.item}>
					<div className={s.wrapperNum}>
						<div className={s.number}>4</div>
						<div className={s.border}></div>
					</div>
					<div className={s.text}>
						<div>{text.h4}</div>
						<div>{text.t4}</div>
					</div>
				</div>

				<div className={s.item}>
					<div className={s.wrapperNum}>
						<div className={s.number}>5</div>
					</div>
					<div className={s.text}>
						<div>{text.h5}</div>
						<div>{text.t5}</div>
					</div>
				</div>
			</div>
			<Footer />
		</>
	);
};
