import React, { useEffect, useState } from 'react';
import { useUser } from './hooks/user';
import { TelegramWebAppContext } from './context/telegram';
import { UserContext } from './context/user';
import { AppRoutes } from './AppRoutes';
import { post } from './lib/api';
import { TokenContext } from './context/auth';
import { useOptions } from './hooks/misc';
import { OptionsContext } from './context/misc';
import svg from './static/images/loader.svg';
import './App.css';
import { useDispatch } from 'react-redux';
import { setLanguage } from './redux/appState/AppSlice';
import { useLocation } from 'react-router-dom';

function App() {
	const WebApp = window.Telegram.WebApp;
	const params = new URLSearchParams(window.location.search);
	const token = params.get('token');
	if (token && window.localStorage) {
		window.localStorage.setItem('auth_token', token);
	}
	const [state, setState] = useState({
		loading: false,
		token: window.localStorage ? window.localStorage.getItem('auth_token') : token,
		error: null,
	});
	const options = useOptions();

	const location = useLocation();

	useEffect(() => {
		window.ym(96048295, 'hit', location.pathname);
	}, [location]);
	if(WebApp) {
		WebApp.expand();
		WebApp.enableClosingConfirmation();
	}

	const dispatch = useDispatch();

	useEffect(() => {
		var _mounted = true
		if(WebApp.initData) {
			setState({...state, loading: true})
			post('/users/loginByInitData', {}, decodeURIComponent(WebApp.initData) + "&type=client").then(
				data => {
					dispatch(setLanguage(data.user.language))
					_mounted && setState({
						loading: false,
						token: data.token,
						type: 'client',
						error: null
					})
				}
			).catch(alert)
		}
		return () => _mounted = false
	}, [WebApp.initData])

	const { loading, user, error } = useUser(state.token);
	if (state.loading || loading) {
		return (
			<div
				style={{
					textAlign: 'center',
					padding: '20px',
					height: '100vh',
					display: 'flex',
					justifyContent: 'center',
				}}>
				<img src={svg} alt="Loading" style={{ width: 64 }} />
			</div>
		);
	}

	return (
		<>
			<TokenContext.Provider value={state.token}>
				<UserContext.Provider value={user}>
					<OptionsContext.Provider value={options.options}>
						<AppRoutes />
					</OptionsContext.Provider>
				</UserContext.Provider>
			</TokenContext.Provider>
		</>
	);
}

export default App;
