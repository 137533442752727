import service1Img from '../../static/images/service1.png';
import service2Img from '../../static/images/service2.png';
import service3Img from '../../static/images/service3.png';
import { useSelector, useDispatch } from 'react-redux';
import s from './service.module.scss';
const Service = () => {
	const language = useSelector((state) => state.app.language);
	const texts = {
		headers: [
			{ ru: 'Cистема умного поиска', en: 'System of smart search' },
			{ ru: 'Быстрое бронирование', en: 'Built-in analytics' },
			{ ru: 'Правила аукциона', en: 'Auction rules' },
		],
		desc: [
			{
				ru: 'Исключает предложения с завышенной ценой и предлагает варианты напрямую от владельца',
				en: 'Excludes overpriced offers and provides options directly from the owner',
			},
			{
				ru: 'Автоматически отправит запрос на бронирование и пришлет подтвержденные варианты',
				en: 'Will automatically send a booking request and send confirmed options',
			},
			{
				ru: 'Учитывается год выпуска и комплектация. Приоритет у предложений с наименьшим пробегом',
				en: 'Takes into account the year of production and equipment. Priority is given to offers with the lowest mileage',
			},
		],
	};
	const headerText = {
		ru: 'Сервис для аренды байков',
		en: 'Service for rent bikes',
	};
	return (
		<>
			<div className={s.header}>
				<div className={s.point}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="20"
						height="25"
						viewBox="0 0 20 25"
						fill="none">
						<path
							d="M10.1813 0.724487C4.75712 0.724487 0.36142 5.14245 0.36142 10.5901C0.36142 16.0377 4.75712 20.4556 10.1813 20.4556V24.5557L16.8946 17.789C18.8066 15.9897 20.0012 13.4298 20.0012 10.5901C20.0017 5.14245 15.6042 0.724487 10.1813 0.724487ZM10.148 13.6234C9.36483 13.6234 8.65006 13.3245 8.11174 12.8334H8.11039L7.9281 12.6516L7.90469 12.6267L5.83782 10.5797H7.11836C7.11836 8.89884 8.47497 7.53637 10.1476 7.53637C11.8219 7.53637 13.1781 8.89929 13.1781 10.5797C13.1785 12.2605 11.8224 13.6234 10.148 13.6234Z"
							fill="#3F77B5"
						/>
					</svg>
				</div>
				<div>
					{language === 'ru' ? headerText.ru : headerText.en}
					<span>{language === 'ru' ? ' в 3 шага' : ' in 3 steps'}</span>
				</div>
			</div>
			<div className={s.item}>
				<div className={s.text}>
					<div>{language === 'ru' ? texts.headers[0].ru : texts.headers[0].en}</div>
					<div>{language === 'ru' ? texts.desc[0].ru : texts.desc[0].en}</div>
				</div>

				<img src={service1Img} />
			</div>

			<div class={`${s.item} ${s.right}`}>
				<img src={service2Img} />
				<div className={`${s.text} ${s.right} `}>
					<div>{language === 'ru' ? texts.headers[1].ru : texts.headers[1].en}</div>
					<div>{language === 'ru' ? texts.desc[1].ru : texts.desc[1].en}</div>
				</div>
			</div>

			<div class={s.item}>
				<div className={s.text}>
					<div>{language === 'ru' ? texts.headers[2].ru : texts.headers[2].en}</div>
					<div>{language === 'ru' ? texts.desc[2].ru : texts.desc[2].en}</div>
				</div>
				<img src={service3Img} />
			</div>
		</>
	);
};

export default Service;
