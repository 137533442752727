import BikeInfo from './Bike';
import styles from './style.module.scss';
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {get} from "../../lib/api";

const BikeOffers = () => {
	const language = useSelector((state) => state.app.language);
	let headerText = 'Горячие предложения';
	if (language === 'en') {
		headerText = 'Hot offers';
	}

	const [displayBikes, setDisplayBikes] = useState([]);

	useEffect(() => {
		get('/bikes/getDisplayBikes').then((res) => {
			setDisplayBikes(res.bikes);
		}).catch((err) => {
			console.log(err);
		})
	}, []);

	

	return displayBikes.length > 0 && (
		<>
			<div className={styles.header}>
				<div className={styles.icon}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						width="20"
						height="25"
						viewBox="0 0 20 25"
						fill="none">
						<path
							d="M10.1813 0.724365C4.75715 0.724365 0.36145 5.14232 0.36145 10.5899C0.36145 16.0375 4.75715 20.4555 10.1813 20.4555V24.5556L16.8946 17.7889C18.8067 15.9896 20.0012 13.4297 20.0012 10.5899C20.0017 5.14232 15.6042 0.724365 10.1813 0.724365ZM10.148 13.6233C9.36486 13.6233 8.65009 13.3244 8.11177 12.8333H8.11042L7.92813 12.6515L7.90472 12.6266L5.83785 10.5795H7.11839C7.11839 8.89872 8.475 7.53625 10.1476 7.53625C11.822 7.53625 13.1781 8.89917 13.1781 10.5795C13.1786 12.2603 11.8224 13.6233 10.148 13.6233Z"
							fill="#3F77B5"
						/>
					</svg>
				</div>
				{headerText}
			</div>

			<BikeInfo displayBikes={displayBikes} />
		</>
	);
};

export default BikeOffers;
