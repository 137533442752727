import { Children, useEffect, useRef } from 'react';
import styles from './Modal.module.scss';
import { createPortal } from 'react-dom';
const Modal = ({ children, active, className }) => {
	return (
		<>
			{createPortal(
				<div
					className={`${styles.container} ${active && styles.active} ${
						className ? className : ''
					}`}>
					<>
						
						{children}
					</>
				</div>,
				document.body,
			)}
		</>
	);
};

export default Modal;
