import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay, A11y, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { useLayoutEffect, useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setAbs, setActiveIndex } from './../../redux/appState/Dataset';
import styles from './SwiperBlock.module.scss';
import ImageComponent from '../imageComponent/imageComponent';
import ContentLoader from 'react-content-loader';
import { useSwiper } from 'swiper/react';
import { metrikaEvent } from '../../lib/metrika';
import { useLocation } from 'react-router-dom';

const SwiperBlock = ({
	bikes,
	autoplay,
	type,
	defaultIndex,
	activeIndex,
	slideChange,
	modal,
	swiperName,
}) => {
	const ref = useRef();
	const [height, setHeight] = useState(ref?.current?.offsetHeight);
	const [bikesState, setBikesState] = useState([]);

	const lastSwipeTime = useRef(0);

	const location = useLocation();
	const swiperRef = useRef();

	useEffect(() => {
		const array = bikes.map((el, index) => {
			return { ...el, active: activeIndex == index };
		});
		setBikesState(array);
	}, [JSON.stringify(bikes)]);

	useLayoutEffect(() => {
		const resizeBlock = () => {
			if (ref.current) {
				setHeight(ref.current.offsetHeight);
			}
		};

		if (ref.current) {
			setHeight(ref.current.offsetWidth / 1.78);
		}

		const array = bikes.map((el, index) => {
			if (!defaultIndex && index === 0) return { ...el, active: true };
			else if (index === defaultIndex) return { ...el, active: true };
			else return { ...el, active: false };
		});

		setBikesState(array);

		window.addEventListener('resize', resizeBlock);

		return () => window.removeEventListener('resize', resizeBlock);
	}, []);

	const dispatch = useDispatch();

	useEffect(() => {
		if (type && !defaultIndex) {
			dispatch(setActiveIndex({ key: type, activeIndex: 0 }));
		}
	}, []);

	const swipeMainBikes = (click, direction) => {
		switch (swiperName) {
			case 'mini':
				metrikaEvent(
					`${click ? 'клик' : 'свайп'} на ${direction ? 'следующий' : 'предыдущий'} байк(мини)`,
				);
				break;
			case 'highways':
				metrikaEvent(
					`${click ? 'клик' : 'свайп'} на ${direction ? 'следующий' : 'предыдущий'} байк(шоссе)`,
				);
				break;
			case 'luxe':
				metrikaEvent(
					`${click ? 'клик' : 'свайп'} на ${direction ? 'следующий' : 'предыдущий'} байк(премиум)`,
				);
				break;
			default:
				break;
		}
	};

	return (
		<div className={styles.block} ref={ref}>
			{bikesState.length !== 0 && (
				<>
					<Swiper
						onSwiper={(swiper) => {
							swiperRef.current = swiper;
						}}
						style={{
							'--swiper-pagination-color': '#FFF',
							'--swiper-pagination-bullet-inactive-color': 'rgba(0, 0, 0, 0.40)',
							'--swiper-pagination-bullet-inactive-opacity': '1',
							'--swiper-pagination-bullet-size': '7px',
							'--swiper-pagination-bullet-horizontal-gap': '2px',
						}}
						onSlideChange={(swiper) => {
							if (type) {
								dispatch(setActiveIndex({ key: type, activeIndex: swiper.realIndex }));
							}
							const array = [...bikesState];
							array[swiper.realIndex] = { ...array[swiper.realIndex], active: true };
							setBikesState(array);

							if (slideChange) {
								slideChange(swiper.realIndex);
							}

							if (
								swiper &&
								swiper.touchEventsData.lastClickTime != 0 &&
								swiper.touchEventsData.lastClickTime !== lastSwipeTime.current
							) {
								if (swiper.swipeDirection === 'next') {
									if (location.pathname === '/') {
										swipeMainBikes(false, true);
									} else {
										metrikaEvent('свайп на следующий байк(каталог)');
									}
								} else {
									if (location.pathname === '/') {
										swipeMainBikes(false, false);
									} else {
										metrikaEvent('свайп на предыдущий байк(каталог)');
									}
								}
								lastSwipeTime.current = swiper.touchEventsData.lastClickTime;
							}
						}}
						autoplay={autoplay && { delay: 4000 }}
						spaceBetween={30}
						slidesPerView={1}
						loop
						speed={250}
						effect={'cube'}
						initialSlide={defaultIndex ? defaultIndex : 0}
						modules={[Navigation, Pagination, A11y, Autoplay]}
						pagination={{ clickable: true, type: 'bullets' }}>
						{bikesState.map((el, index) => {
							return (
								<SwiperSlide>
									<div className={styles.container}>
										<ImageComponent
											index={index}
											setBikesState={setBikesState}
											active={el.active}
											src={el.img}
											height={height}
										/>
										<div className={styles.nameBike}>{el.name}</div>
									</div>
								</SwiperSlide>
							);
						})}
					</Swiper>
					<button
						onClick={(e) => {
							e.stopPropagation();
							swiperRef.current.slidePrev();
							if (location.pathname === '/') {
								swipeMainBikes(true, false);
							} else {
								metrikaEvent('клик на предыдущий байк(каталог)');
							}
						}}
						className={styles.prev}>
						<span>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="20"
								height="20"
								viewBox="0 0 20 20"
								fill="none">
								<path
									d="M12.4434 14.8889L7.55447 10L12.4434 5.11111"
									stroke="white"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						</span>
					</button>
					<button
						onClick={(e) => {
							e.stopPropagation();
							swiperRef.current.slideNext();
							if (location.pathname === '/') {
								swipeMainBikes(true, true);
							} else {
								metrikaEvent('клик на следующий байк(каталог)');
							}
						}}
						className={styles.next}>
						<span>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="21"
								height="22"
								viewBox="0 0 21 22"
								fill="none">
								<path
									d="M7.97852 16.0417L13.0202 11L7.97852 5.95834"
									stroke="white"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						</span>
					</button>
				</>
			)}
		</div>
	);
};

export default SwiperBlock;
